@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
@import './variables.scss';

html {
  font-size: 10px;
}

html,
body {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #e5e5e5;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 400;
}

a {
  text-decoration: none;
}

a,
a:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-container {
  &--center {
    padding: inherit 10%;
    max-width: 1300px;
    margin: 0 auto;
  }
}

.box-50{
  width: 50px;
  height: 50px;
  position: relative;
}

.end-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
}

a.activeLink {
  color: #333 !important;
}

a.activeLink::after {
  opacity: 1 !important;
}

.headerText {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

div.sc-fznKkj.kTJIvE:nth-child(3n) {
  margin-right: 0;
}

.text-red {
  color: #eb5757 !important;
}

input {
  outline: none;
}

.InputElement {
  height: 40px;
  padding: 15px 10px;
  background: #f9f9f9;
  border-radius: 2px;
  border: none;
  color: #333;
  font-size: 14px;
}

.Invalid {
  border: 1px solid red;
  color: red;
}

.Toastify__toast--success {
  background: rgba(255, 255, 255, 1) !important;
  color: #333333;
  text-align: center;
}

.candidatesContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: end;
  grid-column-gap: 5px;
}

.marginT-30 {
  margin-top: 30px;
}

.marginB-30 {
  margin-bottom: 30px;
}

#nav-dropdown {
  box-shadow: none !important;
}

.rdw-editor-wrapper {
  background-color: #f9f9f9;
  padding: 5px;
  .rdw-editor-main {
    height: 180px;
    font-size: 15px;
  }
}

.select-search {
  width: 220px;
  position: relative;
  box-sizing: border-box;
  margin-right: 2px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search__input {
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  color: #666;
  font-weight: 500;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 7px);
  right: 19px;
  width: 11px;
  height: 11px;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  width: auto;
}

.select-search__options {
  list-style: none;
}

.select-search__option {
  list-style: none;
  display: block;
  height: 36px;
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  position: relative;
  left: -20px;
  text-align: left;
  cursor: pointer;
  color: #666;
  font-weight: 500;
  &:hover {
    color: #408996;
  }
}

.select-search.has-focus {
  input {
    background-color: rgb(250, 248, 248);
  }
}
